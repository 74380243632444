
import React, { Component } from 'react';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';

class Send extends Component {
  constructor(props) {
    super(props);
    this.state = {
      to_address: '',
      amount: '',
      otp: '',
      errors: {},
    };
  }

  componentDidMount() {
    this.props.dispatch(userActions.clearCache());
  }

  onSendCoin = () => {
    const { to_address, amount } = this.state;
    if (!to_address || !amount) {
      this.setState({
        errors: {
          to_address: to_address ? '' : 'Receiver address is required',
          amount: amount ? '' : 'Enter amount',
        },
      });
      return;
    }

    const data = {
      to_address,
      send_token_amount: amount,
    };

    console.log('Data to send:', data);
    this.props.dispatch(userActions.sendCoin(data, this.props));
  };

  validateSendCoinWithOtp = () => {
    const { otp } = this.state;
    if (!otp) {
      this.setState({
        errors: {
          otp: 'OTP is required',
        },
      });
      return;
    }

    const { users } = this.props;
    const { token } = users;
    const data = {
      token: token.token,
      otp,
    };

    console.log('Data to validate OTP:', data);
    this.props.dispatch(userActions.sendCoinOtpVerification(data, this.props));
  };

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  render() {
    const { users } = this.props;
    const { otpState, loading } = users;
    const { to_address, amount, otp, errors } = this.state;

    return (
      <>
        <div>
          <LoadingOverlay active={loading} className="temp001" spinner text="Please wait..." />
        </div>

        <div className="h-screen xl:py-16 py-6 overflow-y-auto bg-[#00000] item-center flex justify-center">
          <div className="fixed w-[400px] md:w-[600px]">
            <div className="rounded-[24px] bg-[#1B1C1D]">
              <div className="flex items-center justify-end px-2 py-2">
                <button className="text-white inline-flex p-2 font-bold rounded-full hover:bg-[#9CA3AF]" type="button" onClick={() => this.onClickMenu('/app/dashboard')}>
                  <MdClose size={20} />
                </button>
              </div>
              <div className="bg-[#1B1C1D] rounded-[24px] px-2 py-4 justify-between items-center text-gray-200">
                <div className="flex items-center justify-center">
                  <div className="flex items-center justify-center w-10 h-10 text-center md:w-16 md:h-16 all-btn-color rounded-full text-[#fff]">
                    <span className="font-bold md:text-lg text-xs flex justify-center items-center">VCNC</span>
                  </div>
                </div>
                <div className="space-y-4 p-4">
                  <div>
                    <label htmlFor="to_address" className="py-0.5">
                      Receiving Address
                    </label>
                    <input
                      id="to_address"
                      className={`border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg block w-full p-2.5 dark:text-white ${errors.to_address ? 'border-red-500' : ''}`}
                      type="text"
                      value={to_address}
                      placeholder="Enter the receiving address"
                      onChange={(e) => this.setState({ to_address: e.target.value, errors: { ...errors, to_address: '' } })}
                    />
                    {errors.to_address && <div className="mt-1 font-serif text-red-500">{errors.to_address}</div>}
                  </div>

                  <div>
                    <label htmlFor="amount" className="py-0.5">
                      Amount
                    </label>
                    <input
                      id="amount"
                      className={`border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg block w-full p-2.5 dark:text-white ${errors.amount ? 'border-red-500' : ''}`}
                      type="text"
                      value={amount}
                      placeholder="Enter the amount"
                      onChange={(e) => this.setState({ amount: e.target.value, errors: { ...errors, amount: '' } })}
                    />
                    {errors.amount && <div className="mt-1 font-serif text-red-500">{errors.amount}</div>}
                  </div>

                  {otpState && (
                    <div>
                      <label htmlFor="otp" className="py-0.5">
                        OTP
                      </label>
                      <input
                        id="otp"
                        className={`border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg block w-full p-2.5 dark:text-white ${errors.otp ? 'border-red-500' : ''}`}
                        type="text"
                        value={otp}
                        placeholder="Enter the OTP"
                        onChange={(e) => this.setState({ otp: e.target.value, errors: { ...errors, otp: '' } })}
                      />
                      {errors.otp && <div className="mt-1 font-serif text-red-500">{errors.otp}</div>}
                    </div>
                  )}

                  <div>
                    <button
                      className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline"
                      onClick={otpState ? this.validateSendCoinWithOtp : this.onSendCoin}>
                      {otpState ? 'Send Coin' : 'Send access code'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users || {};
  const { setting, user, wallet } = overview || {};
  return {
    users,
    setting,
    user,
    wallet,
  };
}

export default connect(mapStateToProps)(Send);
