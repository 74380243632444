/* eslint-disable no-dupe-class-members */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { alertActions, dashboardActions } from '../../_actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { isMobile } from "react-device-detect";
import { HiArrowCircleDown, HiArrowCircleUp } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import QRCode from 'qrcode.react';
import { BiCopy } from "react-icons/bi";
import { AiOutlineReload } from "react-icons/ai";
import { LuCopyCheck, LuCopy } from "react-icons/lu";
// import DepositIndexModal from '../Wallet/components/DepositIndexModal/DepositIndexModal'


class Wallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      priceTempData: {
        MAAEXCH: 1,
        btcPrice: 0,
        dollerPrice: 0,
      },
      fieldsIndex: {},
      errorsIndex: {},
      indexCardDetails: {},
      elementData: {},
      depositIndexCreateModal: false,

      copiedTxId: null,
      // copiedToAddress: null,

      WithdrawIndexCreateModal: false,
      commingSoon: false,
      showConvertCoinModal: false,
      currencyType: null,
      withdrawCoinField: '',
      coinCovertFrom: {
        "from": "INR",
        "to": "MAA",
        "amount": 0
      }
    }
  }

  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    // this.props.dispatch(userActions.getWalletList());
    // this.props.dispatch(userActions.getUserDetails());
    // this.props.dispatch(dashboardActions.getCoinList(temp));
    // this.props.dispatch(userActions.getRecieveCoin());
    // this.props.dispatch(userActions.getTx(temp));
    // this.props.dispatch(userActions.upDateAction())
    // console.log("data",userActions.upDateAction())
    // this.loadScript("https://checkout.razorpay.com/v1/checkout.js");
    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.users.sendCoinSuccess) {
      return {
        ...nextProps,
        WithdrawIndexCreateModal: false,
        fieldsIndex: {},
        errorsIndex: {}
      }
    } else if (nextProps.users.isTicketCreated) {
      // console.log('2222222222222333333333344444444444555555555566666666666');
      return {
        ...nextProps,
        commingSoon: true,
      }
    } else {
      return {
        ...nextProps
      }
    }
  }



  inputChange = (event) => {
    console.log('event________________________', event.target.value);
    event.preventDefault();
    const { fieldsIndex, errorsIndex } = this.state;
    fieldsIndex[event.target.name] = event.target.value;
    errorsIndex[event.target.name] = "";
    console.log(event.target.name, event.target.value);
    this.setState({ fieldsIndex, errorsIndex });
  }

  inputChangeAmount = (event) => {
    event.preventDefault();
    let { users } = this.props;
    let { overview } = users;
    let { setting } = overview;
    let { txFee } = setting ? setting : {};
    console.log("event.target.value  ", event.target.value);
    if (event.target.value >= 0) {
      const { fieldsIndex, errorsIndex } = this.state;
      fieldsIndex[event.target.name] = event.target.value;
      fieldsIndex['amount'] = event.target.value;
      fieldsIndex['fees'] = txFee ? txFee : 0.0001;
      fieldsIndex['recievingAmount'] = parseFloat(event.target.value - (txFee ? txFee : 0.0001)).toFixed(8);
      errorsIndex['amount'] = "";
      this.setState({ fieldsIndex, errorsIndex });
    } else {
      const { errorsIndex } = this.state;
      errorsIndex['amount'] = "Amount should be positive number.";
      this.setState({ errorsIndex });
    }
  }

  handlePageChange = (offset, page) => {
    console.log(offset, page)
  }

  inputChangePrice = (event) => {

    let { users } = this.props;
    let { priceDeta } = users;
    let { btcPrice, dollerPrice } = priceDeta ? priceDeta : {}

    event.preventDefault();
    const { priceTempData } = this.state;
    priceTempData[event.target.name] = event.target.value;
    priceTempData['dollerPrice'] = parseFloat(event.target.value * dollerPrice).toFixed(8);
    priceTempData['btcPrice'] = parseFloat(event.target.value * btcPrice).toFixed(8);
    this.setState({ priceTempData });
  }


  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "txType": "SEND_RECEIVED",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    // this.props.dispatch(userActions.getTx(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "txType": "SEND_RECEIVED",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    // this.props.dispatch(userActions.getTx(data));
  }

  exportPDF = () => {
    let { users } = this.props;

    let { txList } = users;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Send and Received Report";
    const headers = [["DATE", "TRANSACTION TYPE", "AMOUNT", "COIN", "TRANSACTION STATUS"]];
    const data = txList.map(elt => [moment(new Date(parseInt(elt.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm"), elt.txType, elt.amount, elt.coin, elt.status ? "SUCESS" : "PENDING"]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(Date.now() + "_send_received_report.pdf")
  }

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  handleOpenDepositIndexModal = (data) => {
    this.setState({ depositIndexCreateModal: true, });
  }
  handleCloseDepositIndexModal = () => {
    this.setState({ depositIndexCreateModal: false });
  }

  handleOpenWithdrawIndexModal = (data) => {
    console.log('datadatadatadatadatadatadatadata', data);
    this.setState({ WithdrawIndexCreateModal: true, withdrawCoinField: data.id });
  }
  handleCloseWithdrawIndexModal = () => {
    this.setState({ WithdrawIndexCreateModal: false });
  }

  handleOpencommingSoonModal = (data) => {
    this.setState({ elementData: data })
    let resData = {
      coinId: data.id,
      coinType: data.ticker
    }
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    // this.props.dispatch(userActions.getAddressDepositTime(resData, temp))

    let { users } = this.props;
    let { addressDepositTime } = users;
    if (data && data.address) {
      let dataRes = {
        address: data.address
      }

      this.handleOpenDepositIndexModal()
    } else {
      this.props.dispatch(alertActions.success('Address create successfully.'))
    }
  }

  handleClosecommingSoonModal = () => {
    this.setState({ commingSoon: false });
  }

  copyCodeToClipboard = (data) => {
    navigator.clipboard.writeText(data)
    this.props.dispatch(alertActions.success("Copied!"));
  }
  createWalletSubmit = (data) => {
    console.log('data_data$$$$$', data);
    let reqData = {
      coinId: data.id
    }
    console.log('_createWallet_______data.id::', reqData);
    // this.props.dispatch(userActions.createWallet(reqData));
  }


  handleSelectCovertCoin = (e, name) => {
    let { coinCovertFrom } = this.state;

    if (name === "from" || name === "to") {
      let coinsObj = {
        "MAA Coin": "MAA",
        "INR Coin": "INR"
      }
      coinCovertFrom[name] = coinsObj[e.target.value]
    }
    else {
      coinCovertFrom[e.target.name] = e.target.value
    }
    this.setState({ coinCovertFrom });
  }

  handleCoinConvertSubmit = () => {
    let { coinCovertFrom } = this.state;
    let data = {
      "from": coinCovertFrom.from,
      "to": coinCovertFrom.to,
      "amount": parseFloat(coinCovertFrom.amount)
    }
    // this.props.dispatch(userActions.swapCoinAmount(data));
    this.handleCloseConvertCoinModal(false);
  }

  handleCloseConvertCoinModal = (check) => {
    this.setState({ showConvertCoinModal: check });
  }

  copyCodeToClipboard = (data) => {
    navigator.clipboard.writeText(data)
    this.props.dispatch(alertActions.success("Copied!"));
  }

  handleWithdrawSubmit = () => {
    if (this.handaleWithdrawValidation) {
      let data = {
        coinId: this.state.withdrawCoinField,
        address: this.state.fieldsIndex["address"],
        amount: this.state.fieldsIndex["amount"]
      }

      console.log('datadatadatadatadatadatadatadatadata', data);
      // this.props.dispatch(userActions.sendCoin(data));
    }
  }
  handaleWithdrawValidation = () => {
    let fieldsIndex = this.state.fieldsIndex;
    let errorsIndex = {};
    let formIsValid = true;

    //address
    if (!fieldsIndex["address"]) {
      formIsValid = false;
      errorsIndex["address"] = "Please Enter address!";
    }
    //amount
    if (!fieldsIndex["amount"]) {
      formIsValid = false;
      errorsIndex["amount"] = "Please Enter amount!";
    }
    console.log('errorsIndexerrorsIndexerrorsIndexerrorsIndexerrorsIndex', errorsIndex);
    this.setState({ errorsIndex: errorsIndex });
    return formIsValid;
  }
  handleReloadTab = () => {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(dashboardActions.getCoinList(temp));
    // this.props.dispatch(userActions.getRecieveCoin());
    // this.props.dispatch(userActions.getTx(temp));
  }

  copyTxIdToClipboard = (txId) => {
    const tempInput = document.createElement('input');
    tempInput.value = txId;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.setState({ copiedTxId: txId });
    this.props.dispatch(alertActions.success("Copied!"));
  };

  // copyToClipboardAddress = (toAddress) => {
  //   const tempInputAddress = document.createElement('input');
  //   tempInputAddress.value = toAddress;
  //   document.body.appendChild(tempInputAddress);
  //   tempInputAddress.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(tempInputAddress);

  //   // Update the state and show a success alert
  //   this.setState({ copiedToAddress: toAddress }, () => {
  //     this.props.dispatch(alertActions.success('Copied!'));
  //   });
  // };


  render() {

    let { users, dashboard } = this.props;
    let { txList, loading, txTotal } = users;
    let { coinItems } = dashboard;
    const { copiedTxId } = this.state;


    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <main className="relative z-0 flex-1 overflow-y-auto bg-slate-800 focus:outline-none"  >
          <div className="p-3 space-y-6 2xl:p-10 sm:p-5">

            <div className="grid w-full grid-cols-1 gap-4 md:gap-6 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3">
              {
                coinItems && coinItems.length > 0 ?
                  coinItems.map((element, index) =>
                    <>
                      <div className="shadow-2xl wallet-card rounded-xl">
                        <div className="flex justify-between p-2">
                          <div className="ml-2">
                            <img className="w-16 h-16 rounded-full cursor-pointer" src={element && element.imageLinkUrl ? element.imageLinkUrl : null} alt="logo.png" />
                            <h1 className="mt-2 text-2xl font-bold text-center text-white">{element && element.name ? element.name : null}</h1>
                          </div>
                          <div className='pt-2'>
                            <h1 className="text-lg text-left text-white">Withdrawable</h1>
                            <div className="flex items-center">
                              <p className="mr-2 text-[1.2rem] font-bold text-left text-white">1 {element && element.name ? element.name : null} = </p>
                              <p className="mr-3 text-[1.2rem] font-bold text-left text-white">{element && element.price ? element.price : null} INR</p>
                            </div>
                            <div className="flex items-center">
                              <p className="mr-2 text-[1.2rem] font-bold text-left text-white">Available {element && element.name ? element.name : null} = </p>
                              <p className="mr-3 text-[1.2rem] font-bold text-left text-white">{element && element.balance ? (element.balance).toFixed(6) : 0} </p>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 mt-5 border-t border-gray-600">
                          {element && element.address ?
                            <div className="flex items-center justify-center w-full p-3 border-r border-gray-600" onClick={() => this.handleOpencommingSoonModal(element)}>
                              <HiArrowCircleDown className="ml-3 cursor-pointer w-7 h-7 text-emerald-500 lg:ml-3" />
                              <p className="ml-3 text-base text-white cursor-pointer lg:ml-2">Deposit</p>
                            </div>
                            :
                            <div className="flex items-center justify-center w-full p-3 border-r border-gray-600" onClick={() => this.handleOpencommingSoonModal(element)}>
                              <HiArrowCircleDown className="ml-3 cursor-pointer w-7 h-7 text-emerald-500 lg:ml-3" />
                              <p className="ml-3 text-base text-white cursor-pointer lg:ml-2">Create address</p>
                            </div>
                          }

                          <div className="flex items-center justify-center w-full p-3 " onClick={() => this.handleOpenWithdrawIndexModal(element)}>
                            <HiArrowCircleUp className="ml-3 text-orange-500 cursor-pointer w-7 h-7 lg:ml-3" />
                            <p className="ml-3 text-base text-white cursor-pointer lg:ml-2">Withdraw</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) :
                  null
              }
            </div>


            <div className='flex space-x-3'>
              <button onClick={() => this.handleReloadTab()} type="button" class=" whitespace-nowrap px-3 py-2 font-semibold text-lg rounded-r-lg all-btn-color rounded-lg  flex items-center justify-center"><span className='pr-4 space-x-3'><AiOutlineReload className='w-5 h-5' /></span>Reload</button>
              <Link to="/app/transactions" class=" whitespace-nowrap px-3 py-2 font-semibold text-lg rounded-r-lg bg-[#3B82F6]/10 rounded-lg all-btn-color flex items-center justify-center">See All</Link>
            </div>


            <section className="p-6 space-y-4 bg-slate-600  rounded-md">
              <div className="flex flex-wrap items-center justify-between w-full ">
                <div className="">
                  <h3 className="text-xl font-semibold text-white">Transaction History</h3>
                </div>

                {/* <div className='flex space-x-3'>
                  <button onClick={() => this.handleReloadTab()} type="button" class=" whitespace-nowrap px-3 py-2 font-semibold text-lg rounded-r-lg bg-[#3B82F6]/10 rounded-lg text-[#253992] flex items-center justify-center"><span className='pr-4 space-x-3'><AiOutlineReload className='w-5 h-5' /></span>Reload</button>
                  <Link to="/app/transactions" class=" whitespace-nowrap px-3 py-2 font-semibold text-lg rounded-r-lg bg-[#3B82F6]/10 rounded-lg text-[#253992] flex items-center justify-center">See All</Link>
                </div> */}
              </div>

              <div className="pb-2 mt-4 overflow-hidden">
                <div className="max-w-full overflow-auto">
                  <div className="inline-block min-w-full">
                    <div className="">

                      <table className="min-w-full">
                        <thead className="border border-gray-300 all-btn-color">
                          <tr className="text-white">
                            <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">S/n</th>
                            <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">date</th>
                            <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">coin Type</th>
                            <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">Transaction Type</th>
                            <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">Transaction Id</th>
                            <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">amount</th>
                            <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">Received From</th>
                            <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">status</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            txList && txList.length > 0 ?
                              txList.map((element, index) => (
                                <>
                                  {element && element.txType && element.txType == "WITHDRAW" || element && element.txType && element.txType == "DEPOSIT" ?
                                    <tr className="text-center border rounded-lg">
                                      <td className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap">{this.state.offset + index + 1}</td>

                                      <td className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>

                                      <td className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap">{element && element.coinId && element.coinId.name ? element.coinId.name : "-"}</td>

                                      <td className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap">{element && element.txType ? element.txType : "-"}</td>

                                      <td className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap" title={element.txId} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                        <span className='flex items-center justify-center'>{element.txId && element.txId.length > 50 ? element.txId.substring(0, 30) + '...' : '-'}
                                          {element.txId && element.txId.length > 50 && (
                                            <button
                                              onClick={() => this.copyTxIdToClipboard(element.txId)}
                                              className="ml-2 text-center text-blue-500 cursor-pointer"
                                              disabled={copiedTxId === element.txId} // Disable the button if the txId has been copied
                                            >
                                              {copiedTxId === element.txId ? <LuCopyCheck className='w-4 h-4 text-[#007F00]' /> : <LuCopy className='w-4 h-4' />}
                                            </button>
                                          )}</span>
                                      </td>

                                      <td className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap">{element && element.amount ? element.amount : "-"}</td>

                                      <td className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap" title={element.toAddress} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element && element.userRecId && element.userRecId.userName ? element.userRecId.userName : element && element.toAddress.substring(0, 20) ? element.toAddress.substring(0, 20) + "..." : element.toAddress}</td>

                                      {/* <td className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap" title={element.toAddress} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                          <span className='flex items-center justify-center'>{element && element.userRecId && element.userRecId.userName ? element.userRecId.userName : element && element.toAddress.substring(0, 20) ? element.toAddress.substring(0, 20) + "..." : element.toAddress}
                                            {element.toAddress && element.toAddress.length > 50 && (
                                              <button
                                                onClick={() => this.copyToClipboardAddress(element.toAddress)}
                                                className="ml-2 text-center text-blue-500 cursor-pointer"
                                                disabled={copiedToAddress === element.toAddress}
                                              >
                                                {copiedToAddress === element.toAddress ? <LuCopyCheck className='w-4 h-4 text-[#007F00]' /> : <LuCopy className='w-4 h-4' />}
                                              </button>
                                            )}
                                          </span>
                                        </td> */}


                                      {/* <td
                                          className="px-3 py-3 text-xs text-center text-white/70 uppercase bg-slate-800 border border-gray-300 whitespace-nowrap"
                                          title={element.txId}
                                          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                                        >
                                          <span className="flex items-center justify-center">
                                            {element && element.userRecId && element.userRecId.userName
                                              ? element.userRecId.userName
                                              : element && element.toAddress.substring(0, 20)
                                                ? element.toAddress.substring(0, 20) + '...'
                                                : element.toAddress}
                                            {element.toAddress && element.toAddress.length > 50 && (
                                              <button
                                                onClick={() => this.copyToClipboardAddress(element.toAddress)}
                                                className="ml-2 text-center text-blue-500 cursor-pointer"
                                                disabled={copiedToAddress === element.toAddress}
                                              >
                                                {copiedToAddress === element.toAddress ? (
                                                  <LuCopyCheck className="w-4 h-4 text-[#007F00]" />
                                                ) : (
                                                  <LuCopy className="w-4 h-4" />
                                                )}
                                              </button>
                                            )}
                                          </span>
                                        </td> */}

                                      {element && element.status && element.status == 2 ?
                                        < td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold uppercase bg-slate-800"><span className='bg-[#FFE0E0] px-4 py-2 rounded-full text-[#D30000]'>Reject</span></td>
                                        :
                                        element && element.status && element.status == 1 ?
                                          < td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold uppercase bg-slate-800"><span className='bg-[#CDFFCD] px-4 py-2 rounded-full text-[#007F00]'>Approved</span></td>
                                          :
                                          < td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold uppercase bg-slate-800"><span className='bg-[#FFECCC] px-4 py-2 rounded-full text-[#965E00]'>Pending</span></td>
                                      }

                                    </tr >
                                    : null
                                  }

                                </>
                              )) : <tr><td colSpan={8} className="text-center text-gray-700">Data Not Found</td></tr>
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {
                  isMobile ?
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        txTotal && txTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={txTotal / this.state.size}
                            marginPagesDisplayed={0}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        txTotal && txTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={txTotal / this.state.size}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>
                }
              </div>
            </section>

          </div>
        </main>

        {
          this.state.WithdrawIndexCreateModal ? (
            // Modal content
            <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
              <div className="lg:w-[30%] w-full bg-white z-50 mx-auto rounded-lg shadow-lg">
                <div className="flex items-center justify-between p-2 border-b">
                  <div className="flex items-center">
                    <div className="pl-2">
                      <div className="font-semibold text-lg text-[#0E1F51]">Withdraw Balance</div>
                    </div>
                  </div>

                  <div>
                    <button className="inline-flex p-2 rounded-full hover:bg-indigo-50" type="button">
                      <MdClose className='w-6 h-6' onClick={() => this.handleCloseWithdrawIndexModal()} />
                    </button>
                  </div>
                </div>

                <div className='p-4 space-y-3'>


                  {/* <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
                  <div className="relative flex flex-col space-y-1 rounded-md">
                    <label className="text-sm text-[#0E1F51] font-medium">Amount</label>
                    <input className={`w-full bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none ${!this.state.errorsIndex["amount"] ? "'border-opacity-20 " : "order-opacity-100  bg-gray-100"}`}
                      name="amount"
                      value={this.state.fieldsIndex["amount"] ? this.state.fieldsIndex["amount"] : ''}
                      onChange={this.inputChange} placeholder="Amount" type="amount" />
                    {this.state.errorsIndex["amount"] ?
                      <div className="mt-1 font-serif text-red-500 invalid-feedback">
                        {this.state.errorsIndex["amount"]}
                      </div>
                      : null}
                  </div>
                </div> */}


                  <div className="relative px-4 rounded-md shadow-sm">
                    <label className="text-sm text-[#0E1F51] font-medium">Amount</label>
                    <input className={`w-full bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none ${!this.state.errorsIndex["amount"] ? "'border-opacity-20 " : "order-opacity-100  bg-gray-100"}`}
                      name="amount"
                      value={this.state.fieldsIndex["amount"] ? this.state.fieldsIndex["amount"] : ''}
                      onChange={this.inputChange} placeholder="Amount" type="amount" />
                    {this.state.errorsIndex["amount"] ?
                      <div className="mt-1 font-serif text-red-500 invalid-feedback">
                        {this.state.errorsIndex["amount"]}
                      </div>
                      : null}
                  </div>

                  <div className="relative px-4 rounded-md shadow-sm">
                    <label className="text-sm text-[#0E1F51] font-medium">Address</label>
                    <input className={`w-full bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none  ${!this.state.errorsIndex["address"] ? "'border-opacity-20 " : "order-opacity-100  bg-gray-100"}`}
                      // <input className="w-full bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none "
                      name="address"
                      value={this.state.fieldsIndex["address"] ? this.state.fieldsIndex["address"] : ''}
                      onChange={this.inputChange} placeholder="Address" type="address"
                    />
                    {this.state.errorsIndex["address"] ?
                      <div className="mt-1 font-serif text-red-500 invalid-feedback">
                        {this.state.errorsIndex["address"]}
                      </div>
                      : null}
                  </div>
                  <div className="w-64 py-6 mx-auto sm:w-72 ">
                    <button className="flex justify-center w-full px-4 py-3 mx-auto text-sm font-semibold text-white uppercase rounded-md all-btn-color focus:outline-none" type="button"
                      // onClick={displayRazorpay}
                      onClick={() => this.handleWithdrawSubmit()}
                    >Submit</button>
                  </div>
                </div>

              </div>
            </div>
          ) : null
        }

        {
          this.state.depositIndexCreateModal ? (
            <>
              {
                this.state.currencyType && this.state.currencyType === "INR" ?
                  <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
                      <div className="lg:w-[30%] w-full bg-white z-50 mx-auto rounded-lg shadow-lg">
                        <div className="flex items-center justify-between p-2 border-b">
                          <div className="flex items-center">
                            <div className="pl-2">
                              <div className="font-semibold text-lg text-[#0E1F51]">Deposit Balance</div>
                            </div>
                          </div>

                          <div>
                            <button className="inline-flex p-2 rounded-full hover:bg-indigo-50" type="button">
                              <MdClose className='w-6 h-6' onClick={() => this.handleCloseDepositIndexModal()} />
                            </button>
                          </div>
                        </div>

                        <form >
                          <div className="mt-6">
                            <div className="relative px-4 rounded-md shadow-sm">
                              <label className="text-sm text-[#0E1F51] font-medium">Amount</label>
                              <input className="w-full bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none "
                                id="balance"
                                name="balance"
                                placeholder="Your Coin Balance" type="text"
                                value={this.state.fieldsIndex["amt"] ? this.state.fieldsIndex["amt"] : ''} onChange={this.state.inputChangeAmount}
                              />
                            </div>
                          </div>

                          <div className="w-64 py-6 mx-auto mt-6 sm:w-72">
                            <button className=" w-full mx-auto flex justify-center py-3.5 uppercase px-4 text-white text-sm font-semibold rounded-md bg-blue-400 hover:bg-shine  focus:outline-none" type="button">Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50" >
                      <div className="z-50 w-11/12 mx-auto overflow-y-auto rounded shadow-lg md:max-w-md">
                        <div className="px-6 py-4 text-left bg-white modal-content">

                          <div className="flex items-center justify-between pb-3">
                            <p className="text-2xl font-bold text-[#0E1F51]" onClick={() => this.handleCloseDepositIndexModal()}>Deposit</p>


                            <button className="inline-flex p-2 rounded-full hover:bg-indigo-50" type="button">
                              <MdClose className='w-6 h-6' onClick={() => this.handleCloseDepositIndexModal()} />
                            </button>

                            {/* <div className="z-50 cursor-pointer modal-close">
                            <div className="text-2xl font-bold text-[#0E1F51]" onClick={() => this.handleCloseDepositIndexModal()}><MdClose className='w-6 h-6' /></div>
                          </div> */}
                          </div>

                          <div className="flex-col col-span-12 rounded-md xl:col-span-3 sm:col-span-6">
                            <div className="w-full text-center">
                              <h3 className="text-2xl font-semibold leading-9 tracking-wide text-[#0E1F51] uppercase 2xl:tracking-widest">Wallet</h3>
                            </div>
                            <div className="flex flex-wrap justify-center mx-auto text-center text-[#0E1F51]">
                              <div className="pb-4 cursor-pointer">
                                {/* <img src="dist/img/bpc-icon.png " alt="" /> */}
                              </div>
                            </div>
                            <div className="flex items-center justify-center w-full pb-4 ">
                              <div className="p-4 bg-gray-200 rounded-xl">
                                <QRCode size={250} className="mx-auto cursor-pointer" value={
                                  this.state.elementData && this.state.elementData['address'] ? this.state.elementData['address'] : this.state.addressDepositTime && this.state.addressDepositTime['address'] ? this.state.addressDepositTime['address'] : ''
                                } />
                              </div>
                            </div>
                            <div className="w-full">
                              <h4 className="text-lg font-semibold tracking-widest text-center text-[#0E1F51] uppercase cursor-pointer">Scan Qr code</h4>
                            </div>

                            <div className="flex items-center px-3 py-3 mt-4 bg-gray-200 rounded-md flex-nowrap">
                              <p className="mr-2 overflow-hidden text-lg font-light leading-none text-[#0E1F51] whitespace-nowrap">
                                {
                                  this.state.elementData && this.state.elementData['address'] ? this.state.elementData['address'] : this.state.addressDepositTime && this.state.addressDepositTime
                                  ['address'] ? this.state.addressDepositTime['address'] : ''
                                }</p>
                              <span onClick={() => this.copyCodeToClipboard(this.state.elementData && this.state.elementData['address'] ? this.state.elementData['address'] : this.state.addressDepositTime && this.state.addressDepositTime['address'] ? this.state.addressDepositTime['address'] : '')}
                                className="block px-2 py-2 ml-auto mr-0 text-[#0E1F51] rounded-md cursor-pointer bg-gray-300"><BiCopy className="w-5 h-5" />
                              </span>
                            </div>

                          </div>




                        </div>
                      </div>
                    </div>
                  </>
              }
            </>
          ) : null
        }


      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
    dashboard
  };
}
export default connect(mapStateToProps)(Wallet);
