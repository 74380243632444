/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import CoinList from './CoinList';
import CoinListSecond from './CoinListSecond';
// import Timer from 'otp-timer'

// import { RiFacebookFill, RiInstagramLine, RiTwitterLine } from "react-icons/ri";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

class SwapCoin extends Component {
  constructor(props) {
    super(props);
    this.state = {

      isModalOpenSecond: false,
      isModalOpen: false,
      selectedCoin: null,
      selectedCoinSecond: null,

      selectedCoin: {
        imageLinkUrl: '',
      },
      selectedCoinSecond: {
        imageLinkUrl: '',
      },

      fieldsUser: {},
      errorslogin: {},
      optUpdatePassword: false,

      div1Content: null,
      div2Content: null,

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps.users', nextProps.users);
    if (nextProps.users.otpSentUpdatePass) {
      console.log('nextProps.users.otpSentUpdatePass', nextProps.users.otpSentUpdatePass);
      return {
        ...nextProps,
        fieldsUser: {},
        errorslogin: {},
        optUpdatePassword: nextProps.users.otpSentUpdatePass ? nextProps.users.otpSentUpdatePass : false
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    let data = {
      id: this.state && this.state.fieldsUser && this.state.fieldsUser['fromCoin'] ? this.state.fieldsUser['fromCoin'] : null
    }
    // this.props.dispatch(userActions.getUserDetails());
    // this.props.dispatch(userActions.getAllCoins());
    // this.props.dispatch(userActions.getAllCoinsRemoveId(data));
  }


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = this.state.errorslogin;
    fieldsUser[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorslogin });
    if (this.state.fieldsUser['fromCoin']) {
      this.handleOpen()
    }
  }


  updatePasswordSubmit = (e) => {

    if (this.handleValidationUserInfo()) {
      let data = {
        currentpassword: this.state.fieldsUser.currentpassword,
        newpassword: this.state.fieldsUser.newpassword,
        confirmnewpassword: this.state.fieldsUser.confirmnewpassword

      }
      // console.log("updatePasswordSubmit___updatePasswordSubmit:::", data);
      this.props.dispatch(userActions.updatePassword(data, this.props));
    }
  }

  updateEmailSubmit = (e) => {

    if (this.handleValidationEmail()) {
      let data = {
        email: this.state.fieldsUser.email
      }
      // console.log("changeEmail___changeEmail:::", data);
      this.props.dispatch(userActions.changeEmail(data, this.props));
    }
  }


  handleValidationEmail = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    //email
    if (!fieldsUser["email"]) {
      formIsValid = false;
      errorslogin["email"] = "Plz enter Email !";
    }
    // console.log("errorsUsererrorsUsererrorsUser", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }


  handleOpen = () => {
    let data = {
      id: this.state && this.state.fieldsUser && this.state.fieldsUser['fromCoin'] ? this.state.fieldsUser['fromCoin'] : null
    }
    // console.log('datadatadatadata123154564312454656486456', data);
    this.props.dispatch(userActions.getAllCoinsRemoveId(data));
  }

  submitCoinSwap = () => {
    let data = {
      fromCoin: this.state.selectedCoin && this.state.selectedCoin.id ? this.state.selectedCoin.id : null,
      toCoin: this.state.selectedCoinSecond && this.state.selectedCoinSecond.id ? this.state.selectedCoinSecond.id : null,
      amount: this.state.fieldsUser["amount"]
    }
    // console.log('data741774174174174174174174', data);
    this.props.dispatch(userActions.coinSwap(data, this.props))
  }

  handleModalToggle = () => {
    console.log('11111111111111111111111111111');
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  handleCoinSelect = (coin) => {
    console.log('coin111111111111111111111111111', coin);
    this.setState({
      selectedCoin: coin,
    });
    this.handleModalToggle();
  };

  handleModalToggleSecond = () => {
    console.log('11111111111111111111111111111');
    this.setState((prevState) => ({
      isModalOpenSecond: !prevState.isModalOpenSecond,
    }));
  };

  handleCoinSelectSecond = (coin) => {
    console.log('coin111111111111111111111111111', coin);
    this.setState({
      selectedCoinSecond: coin,
    });
    this.handleModalToggleSecond();
  };

  handleSwapClick = () => {
    this.setState((prevState) => ({
      div1Content: prevState.div2Content,
      div2Content: prevState.div1Content,
    }));
  };

  render() {
    // let { users } = this.props;
    // let {  } = users;
    const { isModalOpen, selectedCoin, isModalOpenSecond, selectedCoinSecond } = this.state;
    // console.log('selectedCoinselectedCoinselectedCoin', selectedCoin);
    // console.log('selectedCoinselectedCoinselectedCoin111111111', selectedCoinSecond);

    return (
      <>
        <div className='relative z-0 flex-1 overflow-y-auto focus:outline-none bg-slate-800'>

          {/* <div className="mx-auto md:w-4/6 sm:w-full lg:w-4/6 2xl:w-2/5"> */}
          <div className="py-10 mx-auto md:w-4/5 sm:w-full lg:w-4/5 xl:w-2/5">

            <div className='p-6'>
              <div className="w-full rounded-lg">
                <div className="w-full mx-auto bg-slate-600 flex justify-center items-center pb-4 rounded-lg shadow-lg">
                  <div className='w-full p-2 space-y-6'>
                    <div className='pt-3'>
                      <h1 className='text-center text-[#c8cddb] font-semibold text-2xl'>Swap Coin</h1>
                    </div>

                    <div className='px-4 mt-3 rounded-lg cursor-pointer groupx'>
                      <div className='relative flex justify-between items-center py-2 bg-slate-800 rounded-lg'>
                        <input type="number" id="first_name" className="relative bg-slate-800 text-gray-900 text-lg rounded-lg block md:w-[65%] w-full py-2 px-4 outline-none" placeholder="0" required />
                        <p className='right-0 absolute cursor-pointer border border-[#8d8585] text-[#E5E5E5] font-normal rounded-full md:w-[35%] focus:outline-none px-1 h-9 bg-transparent flex items-center justify-between mr-3' onClick={this.handleModalToggle}>
                          <span className='flex items-center text-base flex-nowrap'>
                            <img src={selectedCoin.imageLinkUrl || 'assets/bnb-bnb-logo.png'} alt='bnb-logo.png' className='mr-2 rounded-full w-7 h-7' />
                            {selectedCoin && selectedCoin.name ? `${selectedCoin.name}` : "Select Coin"}
                          </span>
                          <MdOutlineKeyboardArrowDown className="w-6 h-6" />
                        </p>
                        {isModalOpen && <CoinList onSelectCoin={this.handleCoinSelect} />}
                      </div>

                      {/* <div className="relative flex items-center justify-center pb-1 -mx-2" onClick={this.handleSwapClick}>
                        <BsArrowDownSquareFill size={40} className="absolute z-40 text-white bg-[#A27827] border-4 border-[#A27827] rounded-xl" />
                      </div> */}

                      <div className='relative flex justify-between items-center py-2 mt-5 bg-slate-800 rounded-lg'>
                        <input type="number" id="first_name" className="relative bg-slate-800 text-gray-900 text-lg rounded-lg block md:w-[65%] w-full py-2 px-4 outline-none" placeholder="0" required />
                        <p className='right-0 absolute cursor-pointer border border-[#8d8585] text-[#E5E5E5] font-normal rounded-full md:w-[35%] focus:outline-none px-1 h-9 bg-transparent flex items-center justify-between mr-3' onClick={this.handleModalToggleSecond}>
                          <span className='flex items-center text-base flex-nowrap'>
                            <img
                              src={selectedCoinSecond.imageLinkUrl || 'assets/bnb-bnb-logo.png'}
                              alt='bnb-logo.png'
                              className='mr-2 rounded-full w-7 h-7'
                            />
                            {selectedCoinSecond && selectedCoinSecond.name ? `${selectedCoinSecond.name}` : "Select Coin"}
                          </span>
                          <MdOutlineKeyboardArrowDown className="w-6 h-6" />
                        </p>
                        {isModalOpenSecond && <CoinListSecond onSelectCoin={this.handleCoinSelectSecond} />}
                      </div>

                      <div className="relative flex items-center justify-center pb-1 -mx-2" >
                      </div>

                      {/* <div className="py-2">
                        <input type="number" name='amount' className="block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white"
                          value={this.state.fieldsUser && this.state.fieldsUser["amount"] ? this.state.fieldsUser["amount"] : ''} placeholder="Amount" onChange={this.inputChange} />
                      </div> */}

                      <div className="block w-full pb-8 rounded-md shadow-sm mt-7">
                        <button className="flex justify-center w-full p-3 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none" type="button" onClick={() => this.submitCoinSwap()}>Swap Coin</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>


        {/* {isModalOpen &&
          <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="p-4 bg-white rounded-lg shadow-md">
              <h2 className="mb-4 text-xl font-semibold">Select a coin</h2>
              <ul>
                {coins.map((coin) => (
                  <li key={coin} className="px-4 py-2 cursor-pointer hover:bg-gray-200">
                    {coin}
                  </li>
                ))}
              </ul>
            </div>
          </div>

        } */}
        {/* {
          this.state.fieldsUser['fromCoin'] ?
            <>
            </>
            : null
        } */}

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user
  };
}
export default connect(mapStateToProps)(SwapCoin);
