import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// import { withRouter } from 'react-router';

import Layout from "./Layout";

import Error from "../pages/error";
import Login from "../pages/login";
import CustomLogin from "../pages/CustomLogin";
import Signup from "../pages/signup";
import Forgot from "../pages/forgot";
import Resend from "../pages/Resend";
import VerifyEmail from "../pages/VerifyEmail";
import Contactus from "../pages/contactus/Contactus";
import AboutUs from "../pages/aboutus/AboutUs";
import ValidateToken from "../pages/ValidateToken/ValidateToken";
import forgotUserName from "../pages/forgotUserName/forgotUserName";
import NetworkDetector from '../../src/components/Hoc/NetworkDetector';
import OTP from '../pages/signup/otp'
import NewDetails from '../pages/newDetails/News'




function App() {

  return (
    <HashRouter>
      <Switch>
        {/* <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} /> */}
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={(Layout)} />
        <PublicRoute path="/login" component={(Login)} />
        <PublicRoute path="/news/:id" component={(NewDetails)} />
        <PublicRoute path="/otp/:email?/:password?/:countryCode?/:mobNo?" component={(OTP)} />
        <PublicRoute path="/customlogin/:id" component={(CustomLogin)} />
        <PublicRoute exact path="/" component={(Login)} />
        <PublicRoute exact path="/signup/:id?" component={(Signup)} />
        <PublicRoute exact path="/forgot" component={(Forgot)} />
        <PublicRoute exact path="/forgotUserName" component={(forgotUserName)} />
        <PublicRoute path="/resend" component={(Resend)} />
        <PublicRoute path="/contactus" component={(Contactus)} />
        <PublicRoute path="/aboutus" component={(AboutUs)} />
        <PublicRoute path="/verifyemail/:id/:token" component={(VerifyEmail)} />
        <PublicRoute path="/validateToken/:id" component={(ValidateToken)} />
       
        <Route component={Error} />
      </Switch>
      
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          // window.sessionStorage.getItem('user') ? (
          true ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }


  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          false ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}


export default NetworkDetector(App);
// export default (App);