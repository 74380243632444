/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { dashboardActions, userActions, alertActions } from '../../_actions';
import { connect } from 'react-redux';
import { AiOutlineArrowLeft } from "react-icons/ai"
import { FaAngleLeft } from "react-icons/fa";

import "jspdf-autotable";

import { confirmAlert } from 'react-confirm-alert';

export class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      fieldslogin: {},
      errorslogin: {},
      option: {},
      showLeaderSection: false,
      activeTab: 1,
      isPackageListOpen: true,
      isHistoryOpen: false,
      isBioPreservation: false,
      isCertificates: false,
      isHovered: false,
      isNetwork: true,
      isTether: false,
      isPayment: false,
      fieldTicketId: false,
      transactionModal: false,
      openText: false,
      clickedOutside: 0,
      clickedOutBanking: false,
      isReceive: false,
      addressName: '',
      toAddress: '',
      amount: '',
      coin: { symbol: 'GCN' }, // Replace with your initial coin state
      loader: false,
      coinSelect: '',
      cAddress: '',
      amount1: '',

      walletName: ''
    }
  }

  componentDidMount() {
    this.Saveaddresss()
    let addresss = this.props.match && this.props.match.params && this.props.match.params.address ? this.props.match.params.address : null;
    let tempsData = {
      address: addresss
    }
    console.log('addresss', addresss);
    // getWalletListData
    this.props.dispatch(userActions.getAddressList())
    // this.props.dispatch(userActions.getWalletList(tempsData));
    this.props.dispatch(userActions.getUserWalletToBuy(tempsData))
    this.props.dispatch(userActions.getProfile());
    // this.props.dispatch(userActions.getPackageList(data2));
    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(nextProps) {

    if (nextProps.users.sendCoinSuccess) {
      return {
        ...nextProps,
        fieldslogin: {},
        errorslogin: {}
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  handleBuyPackage = (data) => {
    let datatemp = {
      packageId: data.id,
      amount: data.price
    }
    confirmAlert({
      title: 'Confirm to buy package?',
      p: `Are you sure to spend ${data.price} $?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.dispatch(dashboardActions.buyPackage(datatemp))
            // this.props.dispatch(userActions.getProfileDataPi())
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
















  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleOpenPackageList = () => {
    this.setState({ isPackageListOpen: true });
  };

  handleClosePackageList = () => {
    this.setState({ isPackageListOpen: false });
  };

  handleOpenNetwork = () => {
    this.props.history.push('/app/Send', "")
    // this.setState({ isNetwork: true });
  };

  handleCloseNetwork = () => {
    this.setState({ isNetwork: false });
  };

  handleOpenTether = () => {
    this.setState({ isTether: true })
  };

  handleCloseTether = () => {
    this.setState({ isTether: false })
  }

  handleOpenPayment = () => {
    this.props.history.push('/app/Payment', "")
    // this.setState({ isPayment: true })
  };

  handleClosePayment = () => {
    this.setState({ isPayment: false })
  }
  handlePrimaryWalletOpen = () => {
    this.setState({ fieldTicketId: true })
  }
  handlePrimaryWalletClose = () => {
    this.setState({ fieldTicketId: false })
  }

  handleTransactionOpen = () => {
    this.setState({ transactionModal: true })
  }
  handleTransactionClose = () => {
    this.setState({ transactionModal: false })
  }
  handleRes = () => {
    this.setState({ openText: !this.state.openText })
  }
  handleResOutBanking = () => {
    this.setState({ clickedOutBanking: !this.state.clickedOutBanking })
  }

  handleResOut = (data) => {
    console.log('data_____________________', data);
    this.setState({ clickedOutside: data })
  }

  handleOpenReceive = () => {
    this.setState({ isReceive: true })
  };

  handleCloseReceive = () => {
    this.setState({ isReceive: false })
  }

  onCreateWallet = () => {
    if (!this.state.addressName) {
      alertActions.error('Wallet name is required');
      return;
    }
    let data = {
      walletName: this.state.addressName
    }
    // console.log('data=========>', data);
    this.props.dispatch(userActions.createWallet(data))

  }

  Saveaddresss = (temp) => {

    this.setState({ walletName: temp && temp.walletName })




    console.log("temptemptemptemptemp", temp);


    this.handlePrimaryWalletClose()
    let addresss = temp && temp.address


    this.props.dispatch(userActions.Saveaddresss(addresss))


    let temps = {
      address: addresss
    }
    this.props.dispatch(userActions.getUserWalletToBuy(temps))
    this.props.dispatch(userActions.getAddressList(temps))
    this.props.dispatch(userActions.getWalletList(temps))


    if (temp && temp.walletName || temp && temp.walletName == null) {
      let tempWallet = temp && temp.walletName ? `/app/dashboard/${temp.walletName}/${temp.address}` : `/app/dashboard/Primary/${temp.address}`
      console.log('tempWallet__________________________', tempWallet);

      this.props.history.push(tempWallet)
    }


  }


  onSendCoin = () => {
    const { toAddress, amount, coin } = this.state;
    const { users } = this.props;
    let { getSavedAddress, } = users;


    const data = {
      from_address: getSavedAddress,
      to_address: this.state.toAddress,
      send_token_amount: this.state.amount,
    };

    console.log('datadatadatadata', data);
    // dispatch(userActions.sendCoin(data, props));
    this.props.dispatch(userActions.sendCoin(data))

    // this.setState({ loader: true });
    // setTimeout(() => {
    //   this.setState({ loader: false });
    // }, 4000);

  }

  send_wallet = () => {

    const { toAddress, amount, coin } = this.state;
    const { users } = this.props;
    let { getUserWalletToBuy } = users;

    let gcn_balancee = getUserWalletToBuy && getUserWalletToBuy.gcnAmount
    let gcn_balance = Number(this.state.amount1)


    console.log("gcn_balancee>=gcn_balancegcn_balancee>=gcn_balancegcn_balancee>=gcn_balance", gcn_balancee >= gcn_balance);
    if (gcn_balancee >= gcn_balance) {


      let data = {
        "address": this.state.coinSelect,
        "amount": gcn_balance

      }


      console.log("getSavedAddressgetSavedAddressgetSavedAddress", data);
      this.props.dispatch(userActions.buyGCN(data, this.props))

    } else {
      this.props.dispatch(alertActions.error("Insuffcient balance"));
      // alertActions.error("Insuffcient balance")
    }



  }


  contact_address = () => {
    const { cAddress } = this.state;
    const { users } = this.props;
    let { getAddressList } = users;


    let data = {
      "cAddress": cAddress,
      "address": this.props.match.params.address ? this.props.match.params.address : getAddressList && getAddressList[0] && getAddressList[0].address ? getAddressList[0].address : null
    }


    console.log("addContractAddressaddContractAddressaddContractAddress", data);
    this.props.dispatch(userActions.addContractAddress(data))

  }

  handleCoinChange = (event) => {
    this.setState({ coinSelect: event.target.value });
  };

  copyTxIdToClipboard = (getSavedAddress) => {
    const tempInput = document.createElement('input');
    tempInput.value = getSavedAddress;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.setState({ copiedTxId: getSavedAddress });
    this.props.dispatch(alertActions.success("Copied!"));
  };







  render() {

    let { users } = this.props;
    let { getUserWalletToBuy } = users;
    const { isPackageListOpen, isBioPreservation, isCertificates, activeTab, copiedTxId, isHistoryOpen, walletName } = this.state;
    console.log('this.state.clickedOutside', this.state.clickedOutside);



    console.log("sbdcghsgcfhdghdgfhdg", getUserWalletToBuy);
    return (


      <div className="h-screen xl:py-16 py-6  overflow-y-auto bg-[#000000] item-center flex justify-center ">
        <div className='flex justify-center items-center px-5'>

          <div className={"w-[350px] md:w-[600px] fixed  z-40 top-10 md:top-16 "}  >

            <div className='bg-[#1B1C1D] px-2 py-4 justify-center items-center text-gray-200 rounded mt-20'>
              {
                this.state.clickedOutside == 0 ? <>
                  <button className="text-white inline-flex p-2 font-bold rounded-full hover:bg-[#9CA3AF]" type="button">
                    <FaAngleLeft className='w-7 h-7' onClick={() => this.onClickMenu('/app/dashboard')} />
                  </button>
                  <div className='md:flex flex-none items-center md:space-x-4 space-x-0 md:space-y-0 space-y-4 justify-center py-4 px-3'>

                    <div
                      onClick={() => this.handleResOut(1)}
                      className='flex cursor-pointer rounded-full all-btn-color px-12 py-3 space-x-2'>
                      <span className=" text-base font-bold flex justify-center items-center text-white "> Crypto</span>
                    </div>
                    <div onClick={() => this.handleResOut(2)}
                      className='flex cursor-pointer rounded-full all-btn-color px-12 py-3 space-x-2'>
                      <span className=" text-base font-bold flex justify-center items-center text-white "> Banking</span>
                    </div>
                  </div>
                </>
                  : null
              }
              {this.state.clickedOutside == 1 ?
                <>
                  <div className='bg-[#1B1C1D] px-2 py-4 justify-between items-center text-white rounded'>
                    <div>
                      <button className="text-white inline-flex p-2 font-bold rounded-full hover:bg-orange-600" type="button" >
                        <AiOutlineArrowLeft size={24} onClick={() => this.handleResOut(0)} />
                      </button>
                    </div>
                    <div className=' flex items-center justify-between text-xl p-4'>
                      <p>1 GCN</p>
                      <p>=</p>
                      <p>2 USDT</p>
                    </div>

                    <div className="space-y-4 p-4">
                      <div>
                        <label htmlFor="" className="py-0.5"> Select Coins</label>
                        {/* <select className=" border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg   block w-full p-2.5    dark:text-white "
                          id="Coins"
                          value={this.state.coinSelect && this.state.coinSelect.address ? this.state.coinSelect.address : ''}
                          onChange={this.handleCoinChange}
                          name="Coins"> */}
                        <select
                          className="border bg-[#1B1C1D] border-gray-400 text-white text-sm rounded-lg block w-full p-2.5 dark:text-white"
                          id="Coins"
                          value={this.state.coinSelect}
                          onChange={this.handleCoinChange}
                          name="Coins"
                        >

                          {/* <option value="">Select Item</option>

{
  getUserWalletToBuy&&getUserWalletToBuy.usdtData&&getUserWalletToBuy.usdtData.length>0?getUserWalletToBuy.usdtData.map((ele,ind)=>(
    <option value={ele && ele.address ? ele.address : null}>{ele && ele.coinId && ele.coinId.name ? ele.coinId.name : null}</option>
   
  )):null

                         

                        } */}
                          <option value="">Select Item</option>
                          {getUserWalletToBuy &&
                            getUserWalletToBuy.usdtData &&
                            getUserWalletToBuy.usdtData.length > 0 ? (
                            getUserWalletToBuy.usdtData.map((ele, ind) => (
                              <option
                                key={ind}
                                value={ele && ele.address ? ele.address : ''}
                              >
                                {ele && ele.coinId && ele.coinId.name ? ele.coinId.name : ''}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No coins available
                            </option>
                          )}


                        </select>
                      </div>

                      <div>
                        <label htmlFor="" className="py-0.5"> Amount</label>
                        <input className=" border bg-transparent border-gray-400 text-gray-400 text-sm rounded-lg   block w-full p-2.5    dark:text-white "
                          type="text"
                          name="Amount"
                          placeholder="Enter the amount"
                          onChange={(e) => { this.setState({ amount1: e.target.value }) }}
                          value={this.state.amount1}
                        >
                        </input>
                      </div>

                      <div onClick={() => this.send_wallet()} className="flex justify-center items-center">
                        <button className=' cursor-pointer rounded-full all-btn-color px-12 py-3 space-x-2'>
                          <span className=" text-base font-bold flex justify-center items-center text-white "> Buy</span>
                        </button>
                      </div>

                    </div>


                  </div>
                </>
                : null}


              {this.state.clickedOutside == 2 ?
                <>
                  <div className='bg-[#1B1C1D] px-2 py-4 justify-between items-center text-gray-500 rounded'>
                    <button className="text-white inline-flex p-2 font-bold rounded-full hover:bg-orange-600" type="button" >
                      <AiOutlineArrowLeft size={24} onClick={() => this.handleResOut(0)} />
                    </button>

                    <div className=' flex items-center justify-between text-3xl p-4'>
                      <p>Comming Soon</p>
                    </div>




                  </div>
                </>
                : null}
            </div>
          </div>


        </div>
      </div >


    )
  }
}
function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting, user, wallet } = overview ? overview : {};
  return {
    users,
    setting,
    user,
    dashboard,
    wallet
  };
}
export default connect(mapStateToProps)(Payment);
