import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userActions } from '../../_actions'
import LoadingOverlay from 'react-loading-overlay'
import 'jspdf-autotable'
import { Helmet } from 'react-helmet'
import moment from 'moment'

class Video extends Component {
  constructor (props) {
    super(props)
    this.state = {
      offset: 0,
      size: 5,
      page: 1,
      errorsticket: {},
      fieldsticket: {},
      ticketTitle: '',
      fieldsAddUser: {},
      fieldTicketId: null,
      dropdownVisible: false,
      isResolved: null,
      selectedValue: '',
      selectedVideo: null,
    }
  }

  componentDidMount () {
    let temp = {
      keyWord: '',
      pageNo: 1,
      size: 100
    }
    this.props.dispatch(userActions.getAllVideo(temp))
    window.scrollTo(0, 0)
  }

  handleThumbnailClick = video => {
    this.setState({ selectedVideo: video })
  }

  handleClosePlayer = () => {
    this.setState({ selectedVideo: null })
  }

  onClickMenu = (url, video, list) => {
    // Create the URL with the video parameter
    // Navigate to the new URL
    this.props.history.push({
      pathname: url,
      search: '',
      state: { ...video, list }
    })
  }


  render () {
    const { users } = this.props
    const { loading, vidodetailsdata } = users
    return (
      <>
        <Helmet>
          <title>VuneCoin - Video</title>
          <meta property='og:image' content='/img/logo.png' />
        </Helmet>
        <div>
          <LoadingOverlay
            active={loading}
            className='temp001'
            spinner
            text='Please wait...'
          />
        </div>
        <div className='relative z-0 flex-1 overflow-y-auto focus:outline-none'>
          <section className='w-11/12 mx-auto pb-16'>
            <div className='container mx-auto p-4'>
              {/* <h1 className="text-3xl text-white font-bold mb-6">Video List</h1> */}
              <div className='flex items-center gap-2'>
                <button
                  type=''
                  className='all-btn-color rounded px-4 py-2 flex items-center gap-2'
                >
                  <img src='/img/logo.png' className='w-8 h-8' alt='' /> VCNC
                  Video
                </button>
              </div>
              <div className='grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-6 mt-6'>
                {vidodetailsdata?.map(video => (
                  <div
                    onClick={() =>
                      this.onClickMenu(
                        `/app/videos/${video?._id}`,
                        video,
                        vidodetailsdata
                      )
                    }
                    key={video._id}
                    className='relative bg-white/20 shadow-md rounded-lg overflow-hidden group'
                  >
                    <img
                      src={video.image}
                      alt={video.videoFileName}
                      className='w-full h-56 object-cover'
                    />
                    {video.isLive && (
                      <span className='absolute top-2 left-2 bg-red-600 text-white text-xs px-2 py-1 rounded-full'>
                        Live
                      </span>
                    )}
                    <span className='absolute inset-0 flex items-center justify-center text-white text-4xl opacity-50 hover:opacity-100'>
                      <i className='fa fa-play-circle' aria-hidden='true'></i>
                    </span>
                    <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                      <svg
                        className='w-16 h-16 text-white'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M14.752 11.168l-6.12 3.555A1 1 0 017 13.795V8.205a1 1 0 011.632-.833l6.12 3.554a1 1 0 010 1.666z'
                        ></path>
                      </svg>
                    </div>
                    <div className='p-4'>
                      <h2 className='text-xl font-semibold text-white'>
                        {video.title}
                      </h2>
                      <div className='flex justify-between items-center'>
                        <p className='text-gray-400 line-clamp-2'>
                          By {video.author}
                        </p>
                        <p className='text-gray-400'>
                          {moment(video.createdAt)
                            .utcOffset('+05:30')
                            .format('DD-MM-YYYY HH:mm')}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>

      </>
    )
  }
}

function mapStateToProps (state) {
  const { users, authentication } = state
  return {
    users,
    authentication
  }
}

export default connect(mapStateToProps)(Video)
