/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { MdAddCall } from "react-icons/md";
import { AiOutlineMail } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

class Signup extends Component {
  constructor(props) {
    super(props);
    // this.address = this.props.location.state
    this.registerSubmit = this.registerSubmit.bind(this);
    this.otpSubmit = this.otpSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentRegister: false,
      showPassword: false,
    }
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.toggleConfirmPasswordVisibility = this.toggleConfirmPasswordVisibility.bind(this);
  }
  componentDidMount() {

    // const { address } = this.props.location.state
    // console.log(address)

    this.props.dispatch(userActions.getCountryCodeList());

  }

  inputChange(e) {
    console.log('e_____________________________', e.target.value);
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    console.log('fieldslogin[name]', fieldslogin[name]);
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  }

  registerSubmit(e) {
    e.preventDefault();
    let data = {

      email: this.state.fieldslogin.email,
      password: this.state.fieldslogin.password,
      countryCode: this.state.fieldslogin && this.state.fieldslogin.countryCode ? this.state.fieldslogin.countryCode : null,
      mobNo: this.state.fieldslogin.mobNo,
      confirmPassword: this.state.fieldslogin.confirmPassword,

    }
    this.props.dispatch(userActions.register(data, this.props));
  }


  resendOtpSubmit = (e) => {

    if (this.handleValidationLogin()) {
      let data = {
        firstName: this.state.fieldslogin.firstName,
        lastName: this.state.fieldslogin.lastName,
        email: this.state.fieldslogin.email,
        // mobNo: this.state.fieldslogin.mobNo,
        password: this.state.fieldslogin.password,
        refByCode: this.props.match.params.id ? this.props.match.params.id : (this.state.fieldslogin.refByCode ? this.state.fieldslogin.refByCode : "ADMIN")
      }
      console.log("registerSubmit___registerSubmit:::", data);
      this.props.dispatch(userActions.register(data, this.props));
    }
  }


  otpSubmit = (e) => {
    e.preventDefault();
    let { token } = this.props.registeruser;
    let { OTP } = this.state;
    this.props.dispatch(userActions.registerValidateOtp({
      token: token,
      otp: OTP
    }, this.props));
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "please Enter OTP!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //firstName
    if (!fieldslogin["firstName"] || fieldslogin["firstName"] === "" || !fieldslogin["firstName"].match("^[A-Za-z]+(((\'|\-|\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorslogin["firstName"] = "Cannot be empty firstname.";
    }

    //userName
    if (!fieldslogin["lastName"] || fieldslogin["lastName"] === "" || !fieldslogin["lastName"].match("^[A-Za-z]+(((\'|\-|\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorslogin["lastName"] = "Cannot be empty lastname.";
    }


    //Email
    const email = fieldslogin["email"];
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!email || !emailPattern.test(email)) {
      formIsValid = false;
      errorslogin["email"] = "Enter a valid email address.";
    }


    if (!fieldslogin["password"] || !fieldslogin["password"].match(/^(?=.*[a-zA-Z0-9])(?=.*[^a-zA-Z0-9]).{6,}$/)) {
      formIsValid = false;
      errorslogin["password"] = "The password field is required and must be at least 6 characters long, containing at least one alphanumeric character and one special character.";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  inputChangeReferal = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (value.length === 8) {
      this.props.dispatch(userActions.getUserByRefCode({ refCode: value }));
    }
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  }




  onClickMenu = (url) => {
    this.props.history.push(url)
  }



  togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }
  toggleConfirmPasswordVisibility() {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  }

  render() {
    let { otpSentRegister, loading, users } = this.props;
    let { userByRefCode, countryCodeList } = users;
    console.log("this.state.fieldslogin::", this.state.fieldslogin);

    return (
      <>

        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>
          <section className="flex items-center w-full">
            <div className="bg-gradient-to-t to-[#5B4DCA] from-[#2D2664] w-full relative lg:block hidden">
              <img className="absolute top-0 left-0" src="/img/Vector.png" alt="" />
              <div className="flex flex-col justify-center items-center h-screen">
                <div>
                  <img src="/img/logo.png" className=" w-72 h-72" alt="" />
                </div>
                <div className="border-t border-[#FFFFFF4D] py-6">
                  <h1 className="text-white text-[60px] font-[700] text-center">Welcome Back</h1>
                  <p className="text-[#FFFFFFA1]/60 text-center text-[26px] font-[400]">To keep connect with us Please <br /> login with your personal information</p>
                </div>
              </div>
              <img className="absolute bottom-0 right-0" src="/img/Vector-1.png" alt="" />
            </div>
            <div className="bg-[#1C2745] w-full ">
              <div className="flex-col justify-center  flex w-5/6 mx-auto space-y-4 xl:w-3/6 h-screen">
                <div className="text-center">
                  <h1 className="text-[45px] font-[700] text-white">Verify  <span className="text-[#4F91F5]">OTP!</span></h1>
                  <p className="text-[22px] font-[400] text-[#FFFFFFA6]">Verification code sent to sa****@gmail.com</p>
                </div>
                <div className="">
                  <div className="">
                    <label className="text-sm font-medium text-white">Access Code</label>
                  </div>
                  <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5] ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500"}`} id="OTP" name="OTP"
                    value={this.state.fieldslogin["OTP"] ? this.state.fieldslogin["OTP"] : ''}
                    onChange={this.inputChange} placeholder="" type="text"
                  />
                  {this.state.errorslogin["OTP"] ?
                    <div className="w-5/6 invalid-feedback text-red -500">
                      {this.state.errorslogin["OTP"]}
                    </div>
                    : null}
                </div>

                {otpSentRegister ?
                  <div className="flex items-center justify-between w-5/6 mx-auto mt-5 mr-auto md:w-3/6 ">
                    <div className="flex items-center py-2 ml-2 lg:ml-0">
                      <Timer textColor={""} seconds={1} minutes={2} ButtonText="Resend Otp" resend={this.resendOtpSubmit} />
                    </div>
                  </div> : null}

                {/* {otpSentRegister ? */}
                <div className=" ">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="button" onClick={this.otpSubmit} >Access Code</button>
                </div>

               
                <div className="py-6">
                  <p className="text-center text-[18px] font-[400] text-[#A09DC5]">Already have a Account ?  <span onClick={() => this.onClickMenu('/login')} className="text-[#508EF3] cursor-pointer">Login Now</span></p>
                </div>
              </div>

            </div>
          </section>


        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentRegister, registeruser, loading } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    registeruser,
    otpSentRegister,
    user,
    users,
    loading
  };
}
export default connect(mapStateToProps)(Signup);
// export default NetworkDetector(connect(mapStateToProps)(Login));