/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userActions } from '../../_actions'
import LoadingOverlay from 'react-loading-overlay'
import 'jspdf-autotable'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { FaUser } from 'react-icons/fa'

class News extends Component {
  constructor(props) {
    super(props)
    this.listInnerRef = React.createRef()
    this.ticketSubmit = this.ticketSubmit.bind(this)
    this.inputChange = this.inputChange.bind(this)
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      errorsticket: {},
      fieldsticket: {},
      ticketTitle: '',
      fieldsAddUser: {},
      fieldTicketId: null,
      dropdownVisible: false,
      isResolved: null,
      selectedValue: '',
      keyWord: '',
      isModalOpen: false
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.users.isTicketCreated) {
      console.log(
        'nextProps.users.isTicketCreated',
        nextProps.users.isTicketCreated
      )
      return {
        ...nextProps,
        fieldsticket: {},
        errorsticket: {}
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  fetchData = () => {
    let temp = {
      status: 'approved',
      keyWord: '',
      pageNo: 1,
      size: this.state.size,
      isStatus: "live"
    }
    this.props.dispatch(userActions.getNewsList(temp))
  }

  componentDidMount() {
    this.fetchData()
    if (this.listInnerRef.current) {
      this.listInnerRef.current.addEventListener('scroll', this.onScroll)
    }
  }

  componentWillUnmount() {
    if (this.listInnerRef.current) {
      this.listInnerRef.current.removeEventListener('scroll', this.onScroll)
    }
  }

  inputChange(e) {
    e.preventDefault()
    let { name, value } = e.target
    this.setState({ [name]: value })
    let fieldsticket = this.state.fieldsticket
    let errorsticket = this.state.errorsticket
    fieldsticket[name] = value
    errorsticket[name] = ''
    this.setState({ fieldsticket, errorsticket })
  }

  ticketSubmit = async e => {
    e.preventDefault()
    if (this.handleValidationTicket()) {
      let { title, msg } = this.state.fieldsticket

      let temp = {
        keyWord: '',
        pageNo: '1',
        size: '5'
      }

      try {
        await this.props.dispatch(
          userActions.createTicket(
            {
              title: title,
              msg: msg
            },
            temp,
            this.props
          )
        )

        this.setState({ isTicketSubmitted: true })
        this.setState({ fieldsticket: {} })

        setTimeout(() => {
          this.setState({ isTicketSubmitted: false })
        }, 4000)
      } catch (error) {
        console.error('Ticket submission error:', error)
      }
    }
  }

  resetForm = e => {
    e.preventDefault()
    this.setState({
      fieldsticket: {},
      errorsticket: {}
    })
    this.hideErrorcomment()
  }

  handleValidationTicket = () => {
    let fieldsticket = this.state.fieldsticket
    let errorsticket = {}
    let formIsValid = true
    if (
      !fieldsticket['title'] ||
      !fieldsticket['title'] === '' ||
      !fieldsticket['title'].trim() ||
      fieldsticket['title'].trim().length === 0
    ) {
      formIsValid = false
      errorsticket['title'] = 'Subject is required!'
    }
    // }

    //msg
    if (!fieldsticket['msg']) {
      formIsValid = false
      errorsticket['msg'] = 'The message is required'
    }
    if (fieldsticket['msg'] && fieldsticket['msg'].length < 20) {
      formIsValid = false
      errorsticket['msg'] = 'The message must be at least 20 characters '
    }

    this.setState({ errorsticket: errorsticket })
    return formIsValid
  }

  onClickMenu = url => {
    this.props.history.push(url)
  }

  onScroll = () => {
    const { users } = this.props
    const { newsDataTotal } = users
    const { scrollTop, scrollHeight, clientHeight } = this.listInnerRef.current

    if (
      scrollTop + clientHeight >= scrollHeight &&
      newsDataTotal >= this.state.size
    ) {
      this.setState(
        prevState => ({ size: this.state.size + 10 }),
        this.fetchData
      )
    }
  }

  handlePageClick = data => {
    let offset = Math.ceil(data.selected * this.state.size)
    this.setState({ offset: offset, page: data.selected })
    let datatemp = {
      keyWord: this.state.keyWord,
      pageNo: data.selected + 1,
      size: this.state.size
    }
    this.props.dispatch(userActions.getTicketList(datatemp))
  }

  validattionReply = () => {
    let fieldsticket = this.state.fieldsticket
    let errorsticket = {}
    let formIsValid = true

    if (
      !fieldsticket['msgg'] ||
      !fieldsticket['msgg'] === '' ||
      !fieldsticket['msgg'].trim() ||
      fieldsticket['msgg'].trim().length === 0
    ) {
      formIsValid = false
      errorsticket['msgg'] = 'The message is required'
    }

    this.setState({ errorsticket: errorsticket })
    return formIsValid
  }

  handleRes = () => {
    if (this.validattionReply()) {
      let datatemp = {
        ticketId: this.state.fieldTicketId,
        msg: this.state.fieldsticket.msgg
      }

      let temp = {
        keyWord: '',
        pageNo: this.state.page,
        size: '5'
      }

      this.props.dispatch(userActions.replyMsgTicket(datatemp, temp))
    }
  }

  TicketListById = data => {
    let temp = {
      keyWord: '',
      pageNo: this.state.page,
      size: '5'
    }

    this.setState({ fieldTicketId: data.id })
    let resDataLast = {
      ticketId: data.id
    }
    this.props.dispatch(userActions.MsgListTicketById(resDataLast, temp))
  }

  onClose = () => {
    this.setState({ fieldTicketId: null })
  }
  updateTicketStatus = data => {
    this.setState({ isResolved: data.isResolve })
    this.setState({ ticketTitle: data?.title || '' })
    let temp = {
      keyWord: '',
      pageNo: this.state.page,
      size: '5'
    }

    this.setState({ fieldTicketId: data.id })

    let resDataLast = {
      ticketId: data.id
    }
    this.props.dispatch(userActions.MsgListTicketById(resDataLast, temp))
  }

  changeSelectedValue = e => {
    this.setState({ selectedValue: e.target.value })
  }
  handlemore = e => {
    this.props.history.push(`/news/${e?._id}`)
  }

  handelKeyWord = e => {
    this.setState({ keyWord: e.target.value })
    let temp = {
      status: 'approved',
      keyWord: e.target.value,
      pageNo: 1,
      size: 10
    }
    this.props.dispatch(userActions.getNewsList(temp))
  }

  openModal = () => {
    this.setState({ isModalOpen: true })
  }

  // Close the modal
  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  render() {
    let { users } = this.props
    let { loading, newsData } = users

    const { isModalOpen } = this.state
    return (
      <>
        <Helmet>
          <title>VuneCoin - news</title>
          <meta property='og:image' content='/img/logo.png' />
        </Helmet>
        <div>
          <LoadingOverlay
            active={loading}
            className='temp001'
            spinner
            text='Please wait...'
          />
        </div>

        <div className='relative z-0 flex-1 overflow-y-auto focus:outline-none'>
          <section className='w-11/12 mx-auto pb-20'>
            <div className='lg:flex justify-between items-center py-10 lg:space-y-0 space-y-3'>
              <div className='flex items-center gap-2 w-full'>
                {/* <h1 className="text-xl sm:text-2xl lg:text-[30px] font-semibold text-white">
                  VCN News
                </h1> */}
                <button
                  type=''
                  className='all-btn-color lg:w-fit w-full rounded-full px-4 py-2 flex items-center gap-2'
                >
                  <img src='/img/logo.png' className='w-8 h-8' alt='' /> VCNC
                  News
                </button>
                <button
                  onClick={this.openModal}
                  type=''
                  className='all-btn-color lg:w-fit w-full rounded-full px-4 py-2 flex items-center gap-2'
                >
                  <img src='/img/image 5.svg' className='w-8 h-8' alt='' />{' '}
                  Chaindest News
                </button>
              </div>

              <div className='relative'>
                <input
                  type='text'
                  className='h-14 lg:w-96 w-full pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none'
                  placeholder='Search news...'
                  value={this.state.keyWord}
                  onChange={this.handelKeyWord}
                />
                <div className='absolute top-4 right-3'>
                  <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />
                </div>
              </div>
            </div>

            <div
              className='grid grid-cols-1  xl:grid-cols-2 gap-4'
              style={{
                height: '100vh',
                overflowY: 'scroll', // Allow scrolling
                scrollbarWidth: 'none', // For Firefox
                msOverflowStyle: 'none' // For IE and Edge
              }}
              ref={this.listInnerRef}
              onScroll={this.onScroll}
            >
              {newsData && newsData.length > 0
                ? newsData.map((elements, index) => (
                  <div className='px-3 mb-6 w-full' key={index}>
                    <div className='flex flex-col sm:flex-row w-full h-full bg-white overflow-hidden rounded'>
                      <div className='w-full sm:w-2/6'>
                        <img
                          className='object-cover h-full w-full'
                          alt='Blog Image'
                          src={
                            elements?.image || '/img/blockchain-img-demo.webp'
                          }
                          onError={e => {
                            e.target.onerror = null
                            e.target.src = '/img/blockchain-img-demo.webp'
                          }}
                        />
                      </div>
                      <div className='w-full sm:w-4/6 p-5'>
                        <div className='space-y-2'>
                          <h2
                            className='text-black leading-normal text-lg hover:underline cursor-pointer'
                            onClick={() => this.handlemore(elements)}
                            dangerouslySetInnerHTML={{
                              __html: elements.title
                            }}
                          ></h2>
                          <p
                            className='text-sm text-black line-clamp-3'
                            dangerouslySetInnerHTML={{
                              __html: elements.story
                            }}
                          ></p>
                        </div>
                        <div className='flex flex-wrap justify-between items-center mt-6'>
                          <div className='inline-flex items-center'>
                            <div className='w-5 h-5 rounded-full overflow-hidden flex justify-center items-center flex-shrink-0 bg-black'>
                              <FaUser color='#fff' size={10} />
                            </div>
                            <div className='flex-1 pl-2'>
                              <h2 className='text-black'>
                                {elements?.author ? elements.author : '-'}
                              </h2>
                            </div>
                          </div>
                          <div>
                            <p className='text-black opacity-50 text-xs'>
                              {moment(
                                new Date(parseInt(elements?.createdAt || '-'))
                              )
                                .utcOffset('+05:30')
                                .format('DD-MM-YYYY HH:mm')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </section>
        </div>

        {isModalOpen && (
          <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50'>
            <div className='relative bg-white rounded-lg p-8 text-center lg:w-[500px] w-11/12 shadow-xl transform transition-all scale-105'>
              {/* Icon */}
              <div className='flex justify-center items-center mb-4'>
                <div className='w-40 h-40 flex items-center justify-center shadow-lg'>
                  <img
                    src='/img/image 5.svg'
                    className='w-full h-full'
                    alt=''
                  />{' '}
                </div>
              </div>

              {/* Modal Title */}
              <h2 className='text-4xl font-bold text-gray-800 mb-2'>
                Coming Soon
              </h2>
              {/* Modal Description */}

              {/* Action Button */}
              <button
                type='button'
                onClick={this.closeModal}
                className='all-btn-color rounded-full px-8 py-2 mt-6 w-60'
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </>
    )
  }
}
function mapStateToProps(state) {
  const { users, authentication } = state
  return {
    users,
    authentication
  }
}
export default connect(mapStateToProps)(News)
